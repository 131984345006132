import { Theme } from "theme-ui";

const ANTHRACITE = {
  100: "#EAECED",
  200: "#D8DDDF",
  300: "#B2BBBF",
  400: "#8B999E",
  500: "#67767C",
  600: "#475155",
  700: "#32393C",
  800: "#282E30",
  900: "#1E2224",
  1100: "#0A0B0C",
};

const ORANGE = {
  100: "#FFEFEE",
  200: "#FFDFDD",
  300: "#FFCAC7",
  600: "#FF6464",
  700: "#E8554C",
};

const ISLE_OF_SAND = {
  100: "#FEFBE7",
  200: "#FDF8D4",
};

const SAHARA_SAND = {
  100: "#FDFCF3",
  200: "#FCFAE9",
};

const buttonBase = {
  borderRadius: 50,
  padding: "6px 20px",
  cursor: "pointer",
  fontWeight: 500,
  transition: "background-color 150ms ease-out, transform 50ms ease-out",
};

const theme: Theme = {
  colors: {
    anthracite: ANTHRACITE,
    orange: ORANGE,
    isle_of_sand: ISLE_OF_SAND,
    sahara_sand: SAHARA_SAND,
    text: ANTHRACITE[700],
    background: "#FFFEF9",
    primary: ORANGE[600],
    secondary: "#FF5C53",
    muted: ANTHRACITE[300],
    border: ANTHRACITE[100],
  },
  fonts: {
    body: "Mona Sans, sans-serif",
    heading: "PolySans, sans-serif",
    display: "PolySans, sans-serif",
  },
  lineHeights: {
    heading: 1.15,
  },
  fontWeights: {
    body: 400,
    heading: 600,
    display: 600,
  },
  fontSizes: {
    display: {
      xs: 24,
      sm: 30,
      md: 36,
      lg: 48,
      xl: 60,
      xxl: 72,
    },
    text: {
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
    },
  },
  breakpoints: [40, 52, 64, 76].map(n => n + "em"),
  sizes: { container: 1128 + 20 },
  text: {
    heading: { color: "anthracite.800" },
  },
  layout: {
    container: {
      px: 20,
    },
    narrow: {
      maxWidth: 744,
    },
  },
  buttons: {
    primary: {
      ...buttonBase,
      color: "#ffffff",
      bg: "primary",
      "&:hover": {
        bg: ORANGE[700],
      },
      "&:active": {
        bg: ORANGE[700],
        transform: "scale(.98)",
      },
    },
    light: {
      ...buttonBase,
      color: "anthracite.800",
      bg: "transparent",
      padding: "6px 18px",
    },
    border: {
      ...buttonBase,
      color: "anthracite.800",
      border: "1px solid",
      borderColor: "anthracite.800",
      bg: "transparent",
      padding: "6px 18px",
      "&:hover": {
        bg: "anthracite.100",
      },
      "&:active": {
        bg: "anthracite.100",
        transform: "scale(.98)",
      },
    },
    dark: {
      ...buttonBase,
      color: "#ffffff",
      bg: "anthracite.800",
      "&:hover": {
        bg: "anthracite.900",
      },
      "&:active": {
        bg: "anthracite.900",
        transform: "scale(.98)",
      },
    },
  },
  cards: {
    primary: {
      backgroundColor: "#ffffff",
      padding: 40,
      borderRadius: 12,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "sahara_sand.200",
      boxShadow: "0px 4px 32px rgba(136, 125, 0, 0.06)",
    },
  },
  forms: {
    input: {
      fontSize: "text.sm",
      lineHeight: "19.6px",
      color: "anthracite.500",
      borderColor: "anthracite.100",
      px: 12,
      py: 2,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: "text.md",
      height: "100%",
      body: { minHeight: "100%", display: "flex", flexDirection: "column" },
    },
    hr: {
      borderColor: "border",
    },
  },
};

export default theme;
